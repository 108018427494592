import { AUTH_SUCCESS, FETCH_PAYMENT_METHODS_SUCCESS, SET_PARTNER } from "app/actionTypes";
import { FETCH_PARTNER_DATA_SUCCESS } from "app/reducers/partnerActionTypes";
import get from "lodash/get";
import isString from "lodash/isString";
import { WEBSITE_TYPE } from "app/constants";

const defaultState = {
	code: undefined,
	websiteType: WEBSITE_TYPE.LISTING, // Define the website type. If nothing is set, the website type by default will be a listing
	enablePromotionCode: false,
	enableSponsorship: false,
	enableDepositDisplay: false,
	strategy: "signupFirst",
	currencyCode: undefined,
	marketingBanners: [],
	marketing: {},
	fallbackSpecialOffer: {},
	availablePaymentMethods: [],
	avisVerifies: {},
	metaTags: [],
	theme: {},
	footer: {},
	useBrowserBackOnProductPage: false,
	showPfsMessage: false,
	showTransportOfferOnly: false,
	reasonToBookList: {},
	reasonToBookListGeneric: {},
	hideContactUsSection: false,
};

const _partnerReducer = (partner, partners = []) => {
	const currentPartner = partners.find(p => p?.code === partner?.code);
	let strategy;
	if (partner?.strategy) {
		strategy = partner.strategy;
	} else if (partners?.length > 0 && currentPartner && currentPartner.strategy) {
		strategy = currentPartner.strategy;
	} else {
		strategy = "signupFirst";
	}
	return {
		...(isString(partner?.code) && { code: partner.code }),
		strategy,
	};
};

export default function partnerReducer(partnerState = defaultState, action) {
	switch (action.type) {
		case FETCH_PARTNER_DATA_SUCCESS: {
			const data = action?.res?.data;
			const footerData = data?.footer;

			return {
				...partnerState,
				partnerRedirectionLink: footerData?.partnerRedirectionLink,
				socialNetworks: footerData?.socialNetworks,
				hideContactUsSection: footerData?.hideContactUsSection,
				marketingBanners: data?.marketingBanners || [],
				marketing: {
					...data?.marketing,
					// On prend par priorité les data configurés au niveau marketingCode dans sanity (partnerState).
					// et si non on prend les data configurés au niveau Shop (data?.marketing?)
					homeBlocks: partnerState.homeBlocks || data?.marketing?.homeBlocks || [],
					heroImageDesktop:
						partnerState.heroImageDesktop || data?.marketing?.heroImageDesktop,
					heroImageDesktopThumbnail:
						partnerState.heroImageDesktopThumbnail ||
						data?.marketing?.heroImageDesktopThumbnail,
					heroImageMobile:
						partnerState.heroImageMobile || data?.marketing?.heroImageMobile,
					heroImageMobileThumbnail:
						partnerState.heroImageMobileThumbnail ||
						data?.marketing?.heroImageMobileThumbnail,
					heroSubtitleDesktop:
						partnerState.heroSubtitleDesktop || data?.marketing?.heroSubtitleDesktop,
					heroTitleDesktop:
						partnerState.heroTitleDesktop || data?.marketing?.heroTitleDesktop,
					heroTitleMobile:
						partnerState.heroTitleMobile || data?.marketing?.heroTitleMobile,
					heroSubtitleMobile:
						partnerState.heroSubtitleMobile || data?.marketing?.heroSubtitleMobile,
				},
				fallbackSpecialOffer: data?.fallbackSpecialOffer || {},
				avisVerifies: data?.avisVerifies || {},
				home: data?.home || {},
				authLandingText: data?.authLandingText || {},
				bookingAuthLandingText: data?.bookingAuthLandingText || {},
				reasonToBookListGeneric: data?.reasonToBookList || {},
				reasonToBookList: data?.marketing?.reasonToBookList || {},
			};
		}
		case AUTH_SUCCESS: {
			return { ...partnerState, ..._partnerReducer(action.partner, action.partners) };
		}
		case FETCH_PAYMENT_METHODS_SUCCESS: {
			const data = get(action, "res.data") || {};
			return { ...partnerState, availablePaymentMethods: data.methods };
		}
		case SET_PARTNER:
			return {
				...partnerState,
				..._partnerReducer(
					{ code: action.partner, strategy: action.strategy },
					action.partners
				),
			};
		case "SET_STRATEGY":
			return Object.assign({}, partnerState, { strategy: action.strategy });
		default:
			return partnerState;
	}
}

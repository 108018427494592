import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AsyncHomeContainer, AsyncSmartDPContainer, AsyncStopoverContainer } from "app/routes";
import { Navigate } from "react-router-dom";
import { BRANDS_WITH_LISTING, STRATEGY, WEBSITE_TYPE } from "app/constants";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import PropTypes from "prop-types";
import { brandPropTypes, strategyShape, websiteTypeShape } from "app/utils/propTypes";
import { getWebsiteType } from "app/reducers/partnerSelector";

const RootContainer = ({ strategy, isAuthenticated, brand, websiteType }) => {
	let redirectUrl;
	if (websiteType === WEBSITE_TYPE.HOLIDAYS) {
		return <AsyncSmartDPContainer />;
	} else if (websiteType === WEBSITE_TYPE.STOPOVER) {
		return <AsyncStopoverContainer />;
	}

	if (strategy === STRATEGY.TRANSACTION_FIRST) {
		redirectUrl = "/not-found";
		if (BRANDS_WITH_LISTING.includes(brand)) {
			redirectUrl = "/listing";
		}
	} else if (strategy === STRATEGY.OAUTH) {
		redirectUrl = "/listing";
	} else if (strategy === STRATEGY.SIGNUP_FIRST) {
		if (isAuthenticated) {
			redirectUrl = "/listing";
		} else {
			redirectUrl = "/home/login";
		}
	}

	if (redirectUrl) {
		return (
			<Navigate
				to={{
					pathname: redirectUrl,
				}}
			/>
		);
	}
	return <AsyncHomeContainer />;
};

RootContainer.propTypes = {
	websiteType: websiteTypeShape,
	strategy: strategyShape,
	isAuthenticated: PropTypes.bool,
	brand: brandPropTypes,
};

const mapStateToProps = state => {
	return {
		brand: state.brand.code,
		strategy: state.partner.strategy,
		websiteType: getWebsiteType(state),
		isAuthenticated: isAuthenticated(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({}, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RootContainer);

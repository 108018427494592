export const FETCH_ALL_STOPOVER_CITIES = "FETCH_STOPOVER_CITIES";
export const FETCH_ALL_STOPOVER_CITIES_SUCCESS = "FETCH_STOPOVER_CITIES_SUCCESS";
export const FETCH_ALL_STOPOVER_CITIES_FAILURE = "FETCH_STOPOVER_CITIES_FAILURE";
export const FETCH_STOPOVER_AVAILABLE_DEPARTURE_DATE_CITIES =
	"FETCH_STOPOVER_AVAILABLE_DEPARTURE_DATE_CITIES";
export const FETCH_STOPOVER_AVAILABLE_DEPARTURE_DATE_CITIES_SUCCESS =
	"FETCH_STOPOVER_AVAILABLE_DEPARTURE_DATE_CITIES_SUCCESS";
export const FETCH_STOPOVER_AVAILABLE_DEPARTURE_DATE_CITIES_FAILURE =
	"FETCH_STOPOVER_AVAILABLE_DEPARTURE_DATE_CITIES_FAILURE";
export const FETCH_STOPOVER_QUOTE = "FETCH_STOPOVER_QUOTE";
export const FETCH_STOPOVER_QUOTE_REQUEST = "FETCH_STOPOVER_QUOTE_REQUEST";
export const FETCH_STOPOVER_QUOTE_SUCCESS = "FETCH_STOPOVER_QUOTE_SUCCESS";
export const FETCH_STOPOVER_QUOTE_FAILURE = "FETCH_STOPOVER_QUOTE_FAILURE";
